import { Alert } from 'antd';
import Button from 'components/Button';
import Card from 'components/Card/Card';
import { Typography } from 'antd';

const { Title } = Typography;

export default function SelectProgram() {
  return (
    <div>
      <Alert
        message="Success! Your facility has been saved. Next, apply for a military fee assistance program."
        type="success"
      />
      <br />

      <Title>Select program</Title>

      <Card
        title={
          <Title level={2} className="mt-4">
            MCCYN Fee Assistance Program
          </Title>
        }
        className="mb-4"
      >
        <p>
          The Military Child Care in Your Neighborhood (MCCYN) program supports eligible families with child care if
          they cannot access military-based care due to distance or a waitlist. The Army Fee Assistance program under
          the MCCYN program provides fee assistance to eligible Army families to offset the cost of community-based
          care.
          <br />
          <br />
          <span className="font-bold">Documents and information you will need:</span> discounts information
          <br />
          <br />
        </p>

        <Button type="primary" htmlType="submit" data-testid="mccynBtn">
          Apply for MCCYN
        </Button>
      </Card>

      <Card
        title={
          <Title level={2} className="mt-4">
            Air Force Home Community Care
          </Title>
        }
        className="mb-4"
      >
        <p>
          The Air Force Home Community Care (HCC) program provides child care for United States Air Force Reserve and
          National Guard members assigned to specific installations during drill weekends. Eligibility requirements
          <br />
          <br />
          <span className="font-bold">Documents and information you will need:</span> Insurance certificate with
          coverage of at least $500,000, staff and resident information
          <br />
          <br />
        </p>

        <Button type="primary" htmlType="submit" data-testid="airForceHccBtn">
          Apply for Air Force HCC
        </Button>
      </Card>

      <Card
        title={
          <Title level={2} className="mt-4">
            Navy Home Community Care
          </Title>
        }
        className="mb-4"
      >
        <p>
          The Navy Home Community Care program provides child care for members of the Navy Reserve Force assigned to
          specific installations during drill weekends.
          <br />
          <br />
          <span className="font-bold">Documents and information you will need:</span> Staff and resident information
          <br />
          <br />
        </p>

        <Button type="primary" htmlType="submit" data-testid="navyHccBtn">
          Apply for Air Force HCC
        </Button>
      </Card>

      <Card
        title={
          <Title level={2} className="mt-4">
            Army Respite Care
          </Title>
        }
        className="mb-4"
      >
        <p>
          The Army Respite program provides no-cost, hourly child care to support the unique child care needs for Army
          Families who meet certain criteria.
          <br />
          <br />
          <span className="font-bold">Documents and information you will need:</span> Rates information
          <br />
          <br />
        </p>

        <Button type="primary" htmlType="submit" data-testid="armyRespiteBtn">
          Apply for Army Respite Care
        </Button>
      </Card>
    </div>
  );
}
