import React, { useEffect, memo, useContext, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import isNull from 'lodash/isNull';
import { Button, message, Spin, InputNumber, Tooltip, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import { useAsync } from 'react-async-hook';
import { copy, LayoutContext, sleep } from 'utils';
import providerService from 'services/providers.service';
import commonService from 'services/common.service';
import { actions as centerActions } from 'features/add-center';
import { ADD_FACILITY_STEPS } from 'constants/index';
import { Home, InHome, FamilyGroup, SchoolChildCare } from 'utils/icons';

import Form from 'components/Form';
import FormItem from 'components/FormItem';
import useProviderEditDisabled from 'hooks/useProviderEditDisabled';
import Card from 'components/Card/Card';
// import Instructions from './v2/Instructions';

const { Title, Paragraph } = Typography;

function CareFacilityProgram({ onCancel, center, setStep, setTab, isFormSubmitting, loading, applicationSubmitted }) {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { collapsed } = useContext(LayoutContext);
  const isSubmitDisabled = useProviderEditDisabled();
  const { loading: centerTypesLoading, result: centerTypes = [] } = useAsync(async () => {
    return await commonService.get('/common/types-of-care', { cache: { interpretHeader: false } });
  }, []);

  const [detailsSet, setDetailsSet] = useState(false);
  useEffect(() => {
    if (!loading && center?.id !== undefined && detailsSet === false) {
      form.setFieldsValue(
        {
          type: center?.type,
          application: copy(center.application, ['id', 'programCategories']),
          displayOnSearch: typeof center?.displayOnSearch === 'boolean' ? center?.displayOnSearch : true,
          locatedOnBase: false,
          centerTypes: centerTypes,
        } || {},
      );

      setDetailsSet(true);
    }
  }, [
    center?.type,
    center.application,
    detailsSet,
    form,
    center?.displayOnSearch,
    center.locatedOnBase,
    center.id,
    center,
    centerTypes,
    loading,
  ]);

  // Keeps track of whether `next` is pressed or `save`
  const shouldGoToNextStep = useRef(false);

  return (
    <div className="w-full" id="add-provider-center-page">
      {/* <Instructions /> */}

      <Spin spinning={isFormSubmitting || loading}>
        <div>
          <Form
            layout="vertical"
            form={form}
            initialValues={{
              type: center?.type,
              application: copy(center.application, ['id', 'programCategories']),
              displayOnSearch: center?.displayOnSearch,
              locatedOnBase: center.locatedOnBase,
              centerTypes: centerTypes,
            }}
            disabled={applicationSubmitted}
            onFinish={async (values) => {
              if (!isNull(values.locatedOnBase)) {
                values.locatedOnBase = false;
              }
              try {
                dispatch(centerActions.setIsFormSubmitting(true));
                const data = await providerService.updateCenter(values, center?.id);

                if (shouldGoToNextStep.current) {
                  setStep(ADD_FACILITY_STEPS.FACILITY_DETAILS, null, data.id);
                }

                message.success('Saved');

                await sleep(100);
                dispatch(centerActions.setCenterInfo(data));
              } catch (error) {
                newrelic.noticeError(error);
                message.error('Unable to save center details.');
              } finally {
                dispatch(centerActions.setIsFormSubmitting(false));
              }
            }}
          >
            {(form) => {
              return (
                <div>
                  <div className="hidden">
                    <Form.Item name={['application', 'id']}>
                      <InputNumber />
                    </Form.Item>
                  </div>
                  <Card
                    title={
                      <>
                        <Title level={3} className="mt-4" role="heading" aria-level={1}>
                          Facility Type
                        </Title>
                        <Paragraph style={{ fontWeight: 'normal' }}>
                          Select the type of facility you are registering.
                        </Paragraph>
                      </>
                    }
                    className="mb-4"
                  >
                    <Spin spinning={centerTypesLoading && !loading} className="w-full flex justify-center">
                      <div className="min-h-[200px] w-full">
                        <FormItem
                          className="-mb-8"
                          name="type"
                          rules={[{ required: true, message: 'Center Type is required.' }]}
                          isSame={(prev, curr) => prev?.id === curr?.id}
                        >
                          <CenterTypeInput
                            centerTypes={centerTypes}
                            id="careFacilityType"
                            collapsed={collapsed}
                            className="justify-center"
                            disabled={applicationSubmitted}
                          />
                        </FormItem>
                      </div>
                    </Spin>
                  </Card>

                  <div className="flex space-x-2">
                    <div className="flex-grow"></div>

                    {!applicationSubmitted && (
                      <Button
                        style={{ borderRadius: '0px' }}
                        htmlType="submit"
                        onClick={() => {
                          shouldGoToNextStep.current = false;
                        }}
                        disabled={isFormSubmitting || isSubmitDisabled}
                        loading={isFormSubmitting}
                      >
                        Save
                      </Button>
                    )}

                    <Button
                      style={{ borderRadius: '0px' }}
                      type="primary"
                      role="button"
                      htmlType={!applicationSubmitted ? 'submit' : undefined}
                      onClick={() => {
                        if (applicationSubmitted) {
                          setStep(ADD_FACILITY_STEPS.FACILITY_DETAILS, null, center?.id);
                          return;
                        }

                        shouldGoToNextStep.current = true;
                      }}
                      disabled={isFormSubmitting || isSubmitDisabled}
                      loading={isFormSubmitting}
                      data-testid="business-info-form-submit"
                    >
                      Next
                    </Button>
                  </div>
                </div>
              );
            }}
          </Form>
        </div>
      </Spin>
    </div>
  );
}

export default memo(CareFacilityProgram);

const ICONS = {
  'Family Child Care Home': FamilyGroup,
  'Child Care Center': Home,
  School: SchoolChildCare,
  'In-Home Provider': InHome,
};

const DESCRIPTIONS = {
  'Family Child Care Home': "Group family home located at an individual's private residence",
  'Child Care Center': 'Facility located in a commercial building',
  School: 'Public/private school or secondary educational institution as recognized by the local school board',
  'In-Home Provider': "Individual providing care located at a family's private residence",
};

const CenterTypeInput = ({ disabled, id, value, onChange, centerTypes, collapsed, className }) => {
  return (
    <div className="relative">
      {disabled && <div className="absolute top-0 left-0 w-full h-full z-10 cursor-not-allowed" />}

      <div
        className={classNames(
          'center-type flex flex-col mb-7 mt-4',
          {
            'sm:flex-row sm:flex-wrap': collapsed,
            'md:flex-row md:flex-wrap': !collapsed,
          },
          className,
        )}
        id={id}
      >
        {centerTypes.map((opt) => {
          const Icon = ICONS[opt.title];
          const title = DESCRIPTIONS[opt.title];
          return (
            <Tooltip title={title} key={opt.id}>
              <Button
                className="group center-box h-[unset] border-none"
                key={opt.title}
                onClick={() => onChange(opt)}
                tabIndex={0}
              >
                <div
                  className={`role-radio-btn flex justify-center items-center ${opt.id === value?.id ? 'active' : ''}`}
                >
                  {Icon && <Icon />}
                </div>
                <div className="w-full">
                  <p className="center-name">{opt.title}</p>
                </div>
              </Button>
            </Tooltip>
          );
        })}
      </div>
    </div>
  );
};

CenterTypeInput.propTypes = {
  centerTypes: PropTypes.array,
  onChange: PropTypes.func,
  value: PropTypes.object,
  id: PropTypes.string,
};
