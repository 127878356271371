const resolutions = {
  screenXs: 480,
  screenXsMin: 480,

  // Small screen / tablet
  screenSm: 576,
  screenMd: 768,
  screenLg: 992,
  screenXl: 1200,
  screenXxl: 1600,
};
export const BREAK_POINTS = {
  ...resolutions,
  screenSmMin: resolutions.screenSm,

  // Medium screen / desktop
  screenMdMin: resolutions.screenMd,

  // Large screen / wide desktop
  screenLgMin: resolutions.screenLg,

  // Extra large screen / full hd
  screenXlMin: resolutions.screenXl,

  // Extra extra large screen / large desktop
  screenXxlMin: resolutions.screenXxl,

  // provide a maximum
  screenXsMax: resolutions.screenSm - 1,
  screenSmMax: resolutions.screenMd - 1,
  screenMdMax: resolutions.screenLg - 1,
  screenLgMax: resolutions.screenXl - 1,
  screenXlMax: resolutions.screenXxl - 1,
};

export const CHILD_CARE_TYPES = [
  { name: 'Full-time', value: 'Full-time' },
  { name: 'Part-time', value: 'Part-time' },
  { name: 'Before Care', value: 'Before Care' },
  { name: 'After Care', value: 'After Care' },
  { name: 'Respite', value: 'Respite' },
];

export const AUTH_STATES = {
  SIGN_IN: 'signIn',
  CONFIRM_SIGN_IN: 'confirmSignIn',
  SIGN_UP: 'signup',
  CONFIRM_SIGN_UP: 'confirmSignUp',
  LOGGED_IN: 'signedIn',
  TOTP_SETUP: 'TOTPSetup',
  REQUIRE_NEW_PASSWORD: 'requireNewPassword',
  FORGOT_PASSWORD: 'forgotPassword',
  RESET_PASSWORD: 'resetPassword',
  CHANGE_PASSWORD: 'changePassword',
  PROVIDER_ACTIVATE: 'providerActivate',
};

export const RESET_ALL = 'reset-all';

export const LOGOUT_TIMEOUT = 60; // minutes

export const PROVIDER_STATUSES = {
  INCOMPLETE: 'INCOMPLETE',
  PENDING: 'PENDING',
  VERIFYING: 'VERIFYING',
  DOC_REVIEW_REQUIRED: 'DOC_REVIEW_REQUIRED',
  DOC_REVIEW: 'DOC_REVIEW',
  MANAGER_DOC_REVIEW: 'MANAGER_DOC_REVIEW',
  READY_FOR_QC: 'READY_FOR_QC',
  QC_REVIEW: 'QC_REVIEW',
  QC_MANAGER_REVIEW: 'QC_MANAGER_REVIEW',
  QC_REJECTED: 'QC_REJECTED',
  APPROVED: 'APPROVED',
  DEACTIVATED: 'DEACTIVATED',
  DISQUALIFIED: 'DISQUALIFIED',
  QC_EXCEPTION: 'QC_EXCEPTION',
};
export const FAMILY_STATUSES = {
  INCOMPLETE: 'INCOMPLETE',
  PENDING: 'PENDING',
  VERIFYING: 'VERIFYING',
  ENHANCED_REFERRAL_REQUIRED: 'ENHANCED_REFERRAL_REQUIRED',
  ENHANCED_REFERRAL_ASSIGNED: 'ENHANCED_REFERRAL_ASSIGNED',
  ENHANCED_REFERRAL_COMPLETE: 'ENHANCED_REFERRAL_COMPLETE',
  PENDING_PROVIDER_COMPLETION: 'PENDING_PROVIDER_COMPLETION',
  QC_REQUIRED: 'QC_REQUIRED',
  QC_ASSIGNED: 'QC_ASSIGNED',
  QC_REJECTED: 'QC_REJECTED',
  QC_REVIEW: 'QC_REVIEW',
  ENROLLED: 'ENROLLED',
  APPROVED: 'APPROVED',
  QC_EXCEPTION: 'QC_EXCEPTION',
  DEACTIVATED: 'DEACTIVATED',
  DISQUALIFIED: 'DISQUALIFIED',
  MANAGER_REVIEW: 'MANAGER_REVIEW',
};

export const ASSIGNMENT_TYPES = {
  CASE_OWNER: 'Case Owner',
  QC_REVIEW: 'QC Review',
  QC_MANAGER_REVIEW: 'QC Manager Review',
  BGC_REVIEW: 'BGC Review',
  BGC_MANAGER_REVIEW: 'BGC Manager Review',
  DOC_REVIEW: 'Doc Review',
  MANAGER_REVIEW: 'Manager Review',
  MANAGER_DOC_REVIEW: 'Manager Doc Review',
};

export const NAME_REGEX = /^(([A-Za-z]+[\-\']?)*([A-Za-z]+)?\s)+([A-Za-z]+[\-\']?)*([A-Za-z]+)?$/;
export const SINGLE_REGEX = /^[-'a-zA-Z]+$/;
export const BUSINESS_NAME_REGEX = /^(?!\s)(?=.*[a-zA-Z0-9])[-a-zA-Z0-9 ',.&]{2,}$/;
export const FACILITY_NAME_REGEX = /^(?!\s)(?=.*[a-zA-Z0-9])[-a-zA-Z0-9 ',.&]{2,}$/;
export const ZIP_CODE_REGEX = /^\d{5}(?:[-\s]\d{4})?$/;
export const PHONE_NUMBER_REGEX = /^\+1\d{10}|\(\d{3}\)\s\d{3}-\d{4}$/;
export const FORMATTED_PHONE_NUMBER_REGEX = /^\(\d{3}\)\s\d{3}-\d{4}$/;
export const SIMPLE_PHONE_NUMBER_REGEX = /^\+1\d{10}$/;
export const PHONE_NUMBER_EXT_REGEX = /^\d{1,5}$/;
export const PHONE_NUMBER_MASK = '(111) 111-1111';
export const PHONE_EXT_MASK = '11111';
export const TIME_PICKET_MASK = '11:11';
export const DATE_FORMAT = [
  'MMM DD, YYYY',
  'MM/DD/YYYY',
  'YYYY-MM-DD',
  // 'YYYY-M-DD',
  // 'YYYY-MM-D',
  // 'YYYY-M-D',
  // 'YY-M-D',
  // 'YY-MM-D',
  // 'YY-MM-DD',
  // 'YY-M-DD',
  // 'MMM D, YYYY',
  'MM/DD/YYYY',
  'MM/DD/YY',
  'M/DD/YYYY',
  'M/DD/YY',
  'MM/D/YYYY',
  'MM/D/YY',
  'M/D/YYYY',
  'M/D/YY',
];
export const BACKEND_DATE_TIME_FORMAT = 'YYYY-MM-DDTHH:mm';
export const CLIENT_DATE_TIME_FORMAT = 'MM/DD/YYYY - hh:mm A';

export const USA_STATES = [
  {
    name: 'Alabama',
    abbreviation: 'AL',
  },
  {
    name: 'Alaska',
    abbreviation: 'AK',
  },
  {
    name: 'American Samoa',
    abbreviation: 'AS',
  },
  {
    name: 'Arizona',
    abbreviation: 'AZ',
  },
  {
    name: 'Arkansas',
    abbreviation: 'AR',
  },
  {
    name: 'California',
    abbreviation: 'CA',
  },
  {
    name: 'Colorado',
    abbreviation: 'CO',
  },
  {
    name: 'Connecticut',
    abbreviation: 'CT',
  },
  {
    name: 'Delaware',
    abbreviation: 'DE',
  },
  {
    name: 'District Of Columbia',
    abbreviation: 'DC',
  },
  {
    name: 'Federated States Of Micronesia',
    abbreviation: 'FM',
  },
  {
    name: 'Florida',
    abbreviation: 'FL',
  },
  {
    name: 'Georgia',
    abbreviation: 'GA',
  },
  {
    name: 'Guam',
    abbreviation: 'GU',
  },
  {
    name: 'Hawaii',
    abbreviation: 'HI',
  },
  {
    name: 'Idaho',
    abbreviation: 'ID',
  },
  {
    name: 'Illinois',
    abbreviation: 'IL',
  },
  {
    name: 'Indiana',
    abbreviation: 'IN',
  },
  {
    name: 'Iowa',
    abbreviation: 'IA',
  },
  {
    name: 'Kansas',
    abbreviation: 'KS',
  },
  {
    name: 'Kentucky',
    abbreviation: 'KY',
  },
  {
    name: 'Louisiana',
    abbreviation: 'LA',
  },
  {
    name: 'Maine',
    abbreviation: 'ME',
  },
  {
    name: 'Marshall Islands',
    abbreviation: 'MH',
  },
  {
    name: 'Maryland',
    abbreviation: 'MD',
  },
  {
    name: 'Massachusetts',
    abbreviation: 'MA',
  },
  {
    name: 'Michigan',
    abbreviation: 'MI',
  },
  {
    name: 'Minnesota',
    abbreviation: 'MN',
  },
  {
    name: 'Mississippi',
    abbreviation: 'MS',
  },
  {
    name: 'Missouri',
    abbreviation: 'MO',
  },
  {
    name: 'Montana',
    abbreviation: 'MT',
  },
  {
    name: 'Nebraska',
    abbreviation: 'NE',
  },
  {
    name: 'Nevada',
    abbreviation: 'NV',
  },
  {
    name: 'New Hampshire',
    abbreviation: 'NH',
  },
  {
    name: 'New Jersey',
    abbreviation: 'NJ',
  },
  {
    name: 'New Mexico',
    abbreviation: 'NM',
  },
  {
    name: 'New York',
    abbreviation: 'NY',
  },
  {
    name: 'North Carolina',
    abbreviation: 'NC',
  },
  {
    name: 'North Dakota',
    abbreviation: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    abbreviation: 'MP',
  },
  {
    name: 'Ohio',
    abbreviation: 'OH',
  },
  {
    name: 'Oklahoma',
    abbreviation: 'OK',
  },
  {
    name: 'Oregon',
    abbreviation: 'OR',
  },
  {
    name: 'Palau',
    abbreviation: 'PW',
  },
  {
    name: 'Pennsylvania',
    abbreviation: 'PA',
  },
  {
    name: 'Puerto Rico',
    abbreviation: 'PR',
  },
  {
    name: 'Rhode Island',
    abbreviation: 'RI',
  },
  {
    name: 'South Carolina',
    abbreviation: 'SC',
  },
  {
    name: 'South Dakota',
    abbreviation: 'SD',
  },
  {
    name: 'Tennessee',
    abbreviation: 'TN',
  },
  {
    name: 'Texas',
    abbreviation: 'TX',
  },
  {
    name: 'Utah',
    abbreviation: 'UT',
  },
  {
    name: 'Vermont',
    abbreviation: 'VT',
  },
  {
    name: 'Virgin Islands',
    abbreviation: 'VI',
  },
  {
    name: 'Virginia',
    abbreviation: 'VA',
  },
  {
    name: 'Washington',
    abbreviation: 'WA',
  },
  {
    name: 'West Virginia',
    abbreviation: 'WV',
  },
  {
    name: 'Wisconsin',
    abbreviation: 'WI',
  },
  {
    name: 'Wyoming',
    abbreviation: 'WY',
  },
];

export const USA_STATES_DROPDOWN_OPTIONS = [
  {
    name: 'Alabama',
    code: 'AL',
  },
  {
    name: 'Alaska',
    code: 'AK',
  },
  {
    name: 'American Samoa',
    code: 'AS',
  },
  {
    name: 'Arizona',
    code: 'AZ',
  },
  {
    name: 'Arkansas',
    code: 'AR',
  },
  {
    name: 'California',
    code: 'CA',
  },
  {
    name: 'Colorado',
    code: 'CO',
  },
  {
    name: 'Connecticut',
    code: 'CT',
  },
  {
    name: 'Delaware',
    code: 'DE',
  },
  {
    name: 'District Of Columbia',
    code: 'DC',
  },
  {
    name: 'Florida',
    code: 'FL',
  },
  {
    name: 'Georgia',
    code: 'GA',
  },
  {
    name: 'Guam',
    code: 'GU',
  },
  {
    name: 'Hawaii',
    code: 'HI',
  },
  {
    name: 'Idaho',
    code: 'ID',
  },
  {
    name: 'Illinois',
    code: 'IL',
  },
  {
    name: 'Indiana',
    code: 'IN',
  },
  {
    name: 'Iowa',
    code: 'IA',
  },
  {
    name: 'Kansas',
    code: 'KS',
  },
  {
    name: 'Kentucky',
    code: 'KY',
  },
  {
    name: 'Louisiana',
    code: 'LA',
  },
  {
    name: 'Maine',
    code: 'ME',
  },
  {
    name: 'Maryland',
    code: 'MD',
  },
  {
    name: 'Massachusetts',
    code: 'MA',
  },
  {
    name: 'Michigan',
    code: 'MI',
  },
  {
    name: 'Minnesota',
    code: 'MN',
  },
  {
    name: 'Mississippi',
    code: 'MS',
  },
  {
    name: 'Missouri',
    code: 'MO',
  },
  {
    name: 'Montana',
    code: 'MT',
  },
  {
    name: 'Nebraska',
    code: 'NE',
  },
  {
    name: 'Nevada',
    code: 'NV',
  },
  {
    name: 'New Hampshire',
    code: 'NH',
  },
  {
    name: 'New Jersey',
    code: 'NJ',
  },
  {
    name: 'New Mexico',
    code: 'NM',
  },
  {
    name: 'New York',
    code: 'NY',
  },
  {
    name: 'North Carolina',
    code: 'NC',
  },
  {
    name: 'North Dakota',
    code: 'ND',
  },
  {
    name: 'Northern Mariana Islands',
    code: 'MP',
  },
  {
    name: 'Ohio',
    code: 'OH',
  },
  {
    name: 'Oklahoma',
    code: 'OK',
  },
  {
    name: 'Oregon',
    code: 'OR',
  },
  {
    name: 'Pennsylvania',
    code: 'PA',
  },
  {
    name: 'Puerto Rico',
    code: 'PR',
  },
  {
    name: 'Rhode Island',
    code: 'RI',
  },
  {
    name: 'South Carolina',
    code: 'SC',
  },
  {
    name: 'South Dakota',
    code: 'SD',
  },
  {
    name: 'Tennessee',
    code: 'TN',
  },
  {
    name: 'Texas',
    code: 'TX',
  },
  {
    name: 'Utah',
    code: 'UT',
  },
  {
    name: 'Vermont',
    code: 'VT',
  },
  {
    name: 'Virgin Islands',
    code: 'VI',
  },
  {
    name: 'Virginia',
    code: 'VA',
  },
  {
    name: 'Washington',
    code: 'WA',
  },
  {
    name: 'West Virginia',
    code: 'WV',
  },
  {
    name: 'Wisconsin',
    code: 'WI',
  },
  {
    name: 'Wyoming',
    code: 'WY',
  },
];

export const QRIS_STATES = [
  {
    id: 'MD',
    title: 'Maryland',
  },
  {
    id: 'VA',
    title: 'Virginia',
  },
  {
    id: 'CA',
    title: 'California',
  },
  {
    id: 'WA',
    title: 'Washington',
  },
  {
    id: 'FL',
    title: 'Florida',
  },
  {
    id: 'OK',
    title: 'Oklahoma',
  },
  {
    id: 'NC',
    title: 'North Carolina',
  },
  {
    id: 'NV',
    title: 'Nevada',
  },
];

export const selectCommonStyles = {
  control: (provided) => ({ ...provided, height: 30, minHeight: 30 }),
  valueContainer: (provided) => ({ ...provided, height: 30, marginTop: -5 }),
  input: (provided) => ({ ...provided, height: 30, margin: 0 }),
  indicatorsContainer: (provided) => ({ ...provided, height: 30 }),
};

export const militaryServiceTypes = [
  { label: 'Army', value: 'Army' },
  { label: 'Navy', value: 'Navy' },
  { label: 'Marine Corps', value: 'Marine Corps' },
  { label: 'Coast Guard', value: 'Coast Guard' },
  { label: 'Air Force', value: 'Air Force' },
];
export const serviceCategoryTypes = [
  { label: 'Army', value: 'Army' },
  { label: 'Navy', value: 'Navy' },
  { label: 'Marine Corps', value: 'Marine Corps' },
  { label: 'Coast Guard', value: 'Coast Guard' },
  { label: 'Air Force', value: 'Air Force' },
];

export const KEYS = {
  TAB: 9,
  ENTER: 13,
  ESC: 27,
  SPACE: 32,
  PAGEUP: 33,
  PAGEDOWN: 34,
  END: 35,
  HOME: 36,
  LEFT: 37,
  UP: 38,
  RIGHT: 39,
  DOWN: 40,
};

export const TIME_FORMAT = {
  HOURS_MINUTES_SECONDS: 'HH:mm:ss',
  HOURS_MINUTES: 'HH:mm',
  HMS_SHORT_FORMAT: 'h:m:s',
  HOURS_MINUTES_AM_PM: 'hh:mm A',
};

export const AM_PM_TIME_FORMAT_REGEX = /^\d\d:\d\d (A|P)M$/;

export const ADD_FACILITY_STEPS = {
  GET_STARTED: 'get-started',
  BUSINESS_DETAILS: 'business-details',
  FACILITY_DETAILS: 'facility-details',
  CREDENTIALS: 'credentials',
  DETAILS: 'details',
  DOCUMENTS: 'documents',
  DISCOUNTS: 'discounts',
  AGREEMENT: 'agreement',
  SUBMITTED: 'submitted',
  PROGRAM: 'program',
};
export const ADD_FAMILY_STEPS = {
  START: 'start',
  PROGRAMS: 'programs',
  ELIGIBILITY: 'eligibility',
  SPONSOR: 'sponsor',
  SECOND_PARENTS: 'secondParents',
  SECOND_PARENTS_DOCS: 'secondParentDocs',
  CHILDREN: 'children',
  CHILDREN_DOCUMENTS: 'childrenDocuments',
  AGREEMENT: 'agreement',
  SUBMITTED: 'submitted',
  PROVIDER_INFO: 'provider-info',
  INCOME_INFO: 'income-info',
  CERTIFICATES: 'certificates',
  RESERVATION: 'Reservation',
};
export const ADD_FAMILY_STEPS_ORDER = [
  ADD_FAMILY_STEPS.START,
  ADD_FAMILY_STEPS.PROGRAMS,
  ADD_FAMILY_STEPS.SPONSOR,
  ADD_FAMILY_STEPS.SECOND_PARENTS,
  ADD_FAMILY_STEPS.SECOND_PARENTS_DOCS,
  ADD_FAMILY_STEPS.CHILDREN,
  ADD_FAMILY_STEPS.CHILDREN_DOCUMENTS,
  ADD_FAMILY_STEPS.AGREEMENT,
  ADD_FAMILY_STEPS.SUBMITTED,
  ADD_FAMILY_STEPS.RESERVATION,
];
export const ADD_FAMILY_TABS = {
  FORMS: 'forms',
  DOCUMENTS: 'documents',
  INFO: 'info',
  EMPLOYMENT: 'employment',
  SCHOOL_SCHEDULE: 'schoolSchedule',
  ELIGIBILITY: 'eligibility',
  AGREEMENT: 'agreement',
};

export const CHANGE_REQUEST_BG = 'border-2 p-1 border-red-400 bg-red-400 bg-opacity-70';

export const FACILITY_DETAILS_TABS = {
  PROGRAM: 'program',
  BUSINESS_INFO: 'business-info',
  CONTACT_INFO: 'contact-info',
  CREDENTIALS: 'credentials',
  CENTER_DETAILS: 'center-details',
  DISCOUNTS_INFO: 'discounts-info',
  SCHEDULE: 'schedule',
  STAFF_INFO: 'staff-info',
  ADDITIONAL_DOCUMENTS: 'additional-documents',
  AGREEMENT: 'agreement',
};

export const ADD_FACILITY_STEPS_MAPPING = {
  [ADD_FACILITY_STEPS.GET_STARTED]: 0,
  [ADD_FACILITY_STEPS.FACILITY_DETAILS]: 1,
  [ADD_FACILITY_STEPS.CREDENTIALS]: 2,
};

export const statusMap = {
  PENDING: 'New Application',
  SUBMITTED: 'New Application',
  ASSIGNED: 'Assigned',
  RECEIVED: 'Received',
  VERIFYING_PROVIDER_ELIGIBILITY: 'Verifying Eligibility',
  QUALITY_CHECK: 'In QC',
  REVIEW_REQUIRED: 'Needs Review',
  UNDER_REVIEW: 'In Review',
  MANAGER_REVIEW: 'Manager Review',
  ADDITIONAL_ACTION_REQUIRED: 'In Review',
  FINAL_REVIEW_COMPLETE: 'Approved',
  COMPLETED: 'Completed',
  INCOMPLETE: 'Incomplete',
};

export const MAP_BRANCH = {
  1: 'USAF',
  2: 'Army',
  3: 'MarineCorps',
  4: 'Navy',
  5: 'NGA',
  6: 'DOD',
  8: 'USSF',
};

export const EMAIL_STATUS = {
  PENDING: 'PENDING',
  QUEUED: 'QUEUED',
  DELIVERED: 'DELIVERED',
  ERROR: 'ERROR',
};

export const ROLES = {
  // Admin Group
  SYSTEM_ADMIN: 'SystemAdmin',

  // Provider Group
  PROVIDER: 'Provider',
  PROVIDER_MANAGER: 'ProviderManager',
  PROVIDER_COORDINATOR: 'ProviderCoordinator',

  PROVIDER_QC_MANAGER: 'ProviderQCManager',
  PROVIDER_QC_COORDINATOR: 'ProviderQCCoordinator',

  PROVIDER_REVIEWER_MANAGER: 'ProviderReviewerManager',
  PROVIDER_REVIEWER: 'ProviderReviewer',

  PROVIDER_BGC_MANAGER: 'ProviderBGCManager',
  PROVIDER_BGC_COORDINATOR: 'ProviderBGCCoordinator',

  PROVIDER_SUPPORT_SPECIALIST: 'ProviderSupportSpecialist',

  // Family Group
  FAMILY: 'Family',
  FAMILY_MANAGER: 'FamilyManager',
  FAMILY_COORDINATOR: 'FamilyCoordinator',

  FAMILY_QC_MANAGER: 'FamilyQCManager',
  FAMILY_QC_COORDINATOR: 'FamilyQCCoordinator',

  FAMILY_SUPPORT_MANAGER: 'FamilySupportManager',
  FAMILY_SUPPORT_SPECIALIST: 'FamilySupportSpecialist',

  ER_MANAGER: 'ERManager',
  ER_COORDINATOR: 'ERCoordinator',

  FINANCE_MANAGER: 'FinanceManager',

  REPORTING_MANAGER: 'ReportingManager',
  REPORTING_ANALYST: 'ReportingAnalyst',

  PAYMENT_MANAGER: 'PaymentManager',
  PAYMENT_COORDINATOR: 'PaymentCoordinator',

  // Parent Services Group
  PARENT_SERVICES_MANAGER: 'ParentServicesManager',
  PARENT_SERVICES_SPECIALIST: 'ParentServicesSpecialist',
};

export const PHONE_TYPES = ['Home', 'Cell', 'Work'];

export const BREAK_POINTS_MAP = {
  xxl: { xs: false, sm: true, md: true, lg: true, xl: true, xxl: true, screen: 'xxl' },
  xl: { xs: false, sm: true, md: true, lg: true, xl: true, xxl: false, screen: 'xl' },
  lg: { xs: false, sm: true, md: true, lg: true, xl: false, xxl: false, screen: 'lg' },
  md: { xs: false, sm: true, md: true, lg: false, xl: false, xxl: false, screen: 'md' },
  sm: { xs: false, sm: true, md: false, lg: false, xl: false, xxl: false, screen: 'sm' },
  xs: { xs: true, sm: false, md: false, lg: false, xl: false, xxl: false, screen: 'xs' },
};

export const CHECKLIST_TYPES = {
  PROVIDER: 'ProviderApp',
  FAMILY: 'FamilyApp',
  BGC: 'ProviderBackground',
};

export const DAYS_OF_WEEK = [
  { fullName: 'Monday', shortName: 'Mon' },
  { fullName: 'Tuesday', shortName: 'Tue' },
  { fullName: 'Wednesday', shortName: 'Wed' },
  { fullName: 'Thursday', shortName: 'Thu' },
  { fullName: 'Friday', shortName: 'Fri' },
  { fullName: 'Saturday', shortName: 'Sat' },
  { fullName: 'Sunday', shortName: 'Sun' },
];

export const DUMMY_PROVIDER = {
  id: 32715,
  businessName: 'KUEHG Corp',
  businessEmail: '075111@klcorp.com',
  businessPhone: '(703) 527-2288',
  facilityType: 'CENTER',
  displayOnSearch: true,
  minAgeServed: 1,
  maxAgeServed: 73,
  approved: true,
  inHomeProvider: false,
  inPreferredNetwork: true,
  accredited: true,
  programs: ['MCCYN'],
  address: {
    id: 32715,
    addressType: 'Location',
    street: '1227 N Ivy Street',
    city: 'Arlington',
    country: 'US',
    county: 'Arlington',
    state: 'VA',
    postalCode: '22201',
    placeId: 'NULL',
    location: {
      lat: 38.8865331,
      lon: -77.0983986,
      geocoded: true,
    },
  },
  lat: 38.8865331,
  lng: -77.0983986,
};

export const FAMILY_MEMBER_TYPES = {
  CHILD: 'children',
  ADULT: 'adults',
};
export const DESIGNATIONS = [
  'Owner',
  'Primary Caregiver',
  'Assistant',
  'Director',
  'Assistant Director',
  'Lead Teacher',
  'Assistant Teacher',
  'Teacher',
  'Administrator',
  'Cook',
  'Driver',
  'Subsidy Coordinator',
  'Corporate Contact',
  'Other',
].map((label) => ({ label, value: label }));

export const INCOME_FREQUENCIES = [
  {
    id: 'WEEKLY',
    title: 'Weekly',
  },
  {
    id: 'BI_WEEKLY',
    title: 'Bi-Weekly',
  },
  {
    id: 'TWICE_MONTHLY',
    title: 'Twice Monthly',
  },
  {
    id: 'MONTHLY',
    title: 'Monthly',
  },
  {
    id: 'QUARTERLY',
    title: 'Quarterly',
  },
  {
    id: 'ANNUALLY',
    title: 'Annually',
  },
];

export const ENTITY_TYPES = {
  FAMILY: 'FamilyApplication',
  HOUSEHOLD: 'FamilyHousehold',
  SPONSOR: 'SponsorPerson',
  PARENT: 'ParentPerson',
  CHILD: 'ChildPerson',
  CARE_FACILITY: 'CareFacility',
  ATTENDANCE_SHEET: 'AttendanceSheet',
  ELIGIBILITY_DATES: 'EligibilityDates',
  EDUCATION: 'Education',
  ATTACHMENT: 'Attachment',
};

export const MONTH_TO_WEEKS = 4.285714285714286;
export const YEAR_TO_WEEKS = 52.142857142857146;

export const EMAIL_TEMPLATES = [
  {
    title: 'Application Checklist',
    description: 'Template for Application Checklist',
    content: `
    <p
      style='margin-top:50px;margin-right:0cm;margin-bottom:8.0pt;margin-left:30pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Dear Military Provider,</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:30pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Provider ID: <b>123456</b></span>
    </p>
    <p style="text-align: justify; margin-left: 40px;"><span
        style='font-size:16px;line-height:107%;font-family:"Calibri",sans-serif;'>Thank you for contacting Child Care
        Aware&reg; of America. We have received your Military Provider Child Care Fee Assistance application. In order to
        determine your eligibility, we need additional documents. Fee assistance cannot begin until we receive the
        following:</span></p>
    <p style="text-align: justify; margin-left: 40px;"><br></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Facility Type</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please confirm the corresponding facility type that applies to
        your program type.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Military Base Facility</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please confirm whether your program is located on a military
        installation.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>EIN Certificate</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of the Employer Identification Number (EIN) Certificate
        issued by the Internal Revenue Service (IRS). (This document must be referencing the assignment of the EIN to your
        business-changes in address, filing status, exemptions, etc. are not acceptable Instructions on how to obtain this
        document are attached.)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>W-9 form</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of the W-9 form that is attached to this
        correspondence.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1. &nbsp;Please complete
        Line 1 of the W-9 with the facility&rsquo;s legal name.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;1.1 &nbsp; If you have changed your facility&rsquo;s legal name, please contact the Internal Revenue
        Service &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;(IRS) so that your
        business records can match the IRS database.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2. &nbsp;W-9 form is signed
        and dated</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3. &nbsp;Please complete
        this form using only one Employer Identification Number OR Social Security &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp; &nbsp; &nbsp; Number (only Family Child Care Homes may use SSN)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;4. &nbsp;The EIN used on the
        form should be the same number that is on file with the IRS for your business.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><u><span
          style='font-family:"Arial",sans-serif;color:#1E35FF;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Download W-9 From
          here.</span></u></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Payment Information</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please complete the attached direct deposit form.&nbsp;</span>
    </p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1. &nbsp;Family banking
        information will not be accepted</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2. &nbsp;All payments must
        be made directly to provider.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Voided Check/Bank Letter</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of a voided check or bank letter to complete your
        enrolment in direct deposit.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1. &nbsp;Please ensure the
        voided check or bank letter includes account holders name, account number and &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&nbsp;&nbsp; &nbsp; &nbsp;routing number typed from the bank.&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2. &nbsp;Family banking
        information will not be accepted</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;3. &nbsp;All payments must
        be made directly to provider.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Point of Contact</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please confirm the point of contact for your providers
        application.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Child Care location</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please confirm the location of care address is correct.</span>
    </p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Billing address</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please confirm the location of billing address is
        correct.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>License Information</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please provide a copy of your current state child care
        license. Based on the documentation submitted, if &nbsp;your most recent state child care license has expired.
        Please submit a letter/email from your licensor verifying that your facility is permitted to operate.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>School Based Letter</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>You indicated that your facility is a license-exempt
        school-based child care program. Please refer to the attached template and complete a school-based letter. Providers
        approved under a school-based letter are only eligible to receive before/after school fee assistance for children
        aged 5 through 12 years.&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><u><span
          style='font-family:"Arial",sans-serif;color:#1E35FF;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Download School based
          letter template and instruction.</span></u></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>License Qualification</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Based on the documentation submitted, it does not appear that
        your facility holds a state child care license. Please note that the minimum requirement for participation in the
        Military Fee Assistance program is that child care providers have a state child care license. Please contact us at
        1-800-424-2246, Option 4 if you have any questions.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Inspection Information</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of your most recent state licensing inspection report
        from within the past 12 months.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>National Accreditation Information</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of your national accreditation certificate (if
        applicable). The following accreditations are accepted:</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1. &nbsp;School based or
        Child Care Centers:</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;National Association for the Education of Young Children (NAEYC)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;National Accreditation Commission (NAC)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;National Early Childhood Program Accreditation (NECPA)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;Council on Accreditation (COA)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;Cognia - NCA/SACS/NWAC (formerly AdvancED) &ndash; Early Learning Accreditation</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;2. &nbsp;Family Child Care
        Homes</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;Child Development Associate (CDA) credential,</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;Early Childhood Education or Child Development degree (Associate&rsquo;s or higher) Transcripts
        &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp;&nbsp; &nbsp;showing 24 or more credit hours in ECE or
        CD also accepted.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
        &nbsp;&bull;&emsp;National Association of Family Child Care (NAFCC) Accreditation Certificate</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Self Study Accreditation Information</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>You indicated that you are in the process of pursuing national
        accreditation through one of the following organizations:</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&bull;&emsp;National
        Association for the Education of Young Children (NAEYC)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&bull;&emsp;National
        Accreditation Commission (NAC)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&bull;&emsp;National Early
        Childhood Program Accreditation (NECPA)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&bull;&emsp;Council on
        Accreditation (COA)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&bull;&emsp;Cognia -
        NCA/SACS/NWAC (formerly AdvancED) &ndash; Early Learning Accreditation</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&bull;&emsp;National
        Association of Family Child Care (NAFCC)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please submit a letter or email from your accrediting agency
        verifying the date that you enrolled in self-study (for Cognia, please submit your candidacy letter).</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Accreditation Qualification</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Based on the documentation submitted, it does not appear that
        your facility has a certificate from one of the Department of Defense (DoD) approved national accrediting agencies.
        This means that your facility may only qualify as a state licensed-only provider. The following accreditations are
        accepted:</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Child Care Centers:</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;National Association for the Education of Young
        Children (NAEYC)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;National Accreditation Commission (NAC)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;National Early Childhood Program Accreditation
        (NECPA)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;Council on Accreditation (COA)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;Cognia - NCA/SACS/NWAC (formerly AdvancED) &ndash;
        Early Learning Accreditation</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Family Child Care Homes</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;Child Development Associate (CDA)
        credential,</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;Early Childhood Education or Child Development
        degree (Associate&rsquo;s or higher)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&bull;&emsp;National Association of Family Child Care (NAFCC)
        Accreditation Certificate</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>QRIS Information</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>All providers must be either nationally accredited or QRIS
        rated to participate in the fee assitance program.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Based on the documentation submitted, it does not appear that
        your facility has a certificate from one of the Department of Defense (DoD) approved national accrediting
        agencies.&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>This means that your facility may only qualify as a state
        licensed-only provider and if your facility is based in a QRIS pilot state&nbsp;(MD or VA) with a quality rating of
        Level 3 or above to recieve priority in fee assistance approval. You as a QRIS enrolled providers will be reviewed
        in order of rating to determine whether your facility meets the families needs.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please submit a copy of your QRIS rating certificates for
        review.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>QRIS Qualification</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Based on the documentation submitted, it does not appear that
        your facility has a Level-3 QRIS certificate as required by the Department of Defense (DoD). However, approval is
        not impossible for licensed-only and QRIS level 1 or 2 facilities. We&rsquo;ll just need to ensure that there are no
        accredited or QRIS 3, 4, or 5 facilities with space in the area as part of the case review process.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>During your application process you will be notified if you
        are required to &nbsp;participate in the QRIS program (this will be based on current QRIS states). If you have
        previously been approved and require updated information based on new QRIS requirements you will be notified.</span>
    </p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>DoD does set deadlines for providers to achieve certain QRIS
        ratings (see table) to maintain fee assistance eligibility.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Hours of Operation</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please provide your hours of operation.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Registration Fee &amp; Discounts</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of the Fee and Discount Verification Form. Please note:
        Deposits, Comprehensive fees, Curriculum fees, Supply fees, Diaper fees, Activity fees, or any additional fees are
        not covered and should not be listed as a Registration fee. Please hand-sign and date the form.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#1E35FF;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Download Registration Fee
        and Discounts form</span><span style='font-family:"Arial",sans-serif;color:#212A3E;'>.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Age Group and Rates Sheet</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of your current child care rates for all age groups
        that you are licensed to care for, even if the military child does not fall into this age category. Please include
        your rates for all programs offered, including summer camp, before/after school care, school-year, and any upcoming
        rate changes that you have finalized.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><u><span
          style='font-family:"Arial",sans-serif;color:#1E35FF;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Download Age Group and
          Rates Sheet Form</span></u></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Federal Sponsorship Subsides</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of the Federal Sponsorship Certification Letter,
        because you indicated in your application that you receive additional sources of federal child care subsidies.
        Please complete the letter verifying which federal subsidy programs you participate in, and certifying that you
        agree to report these fees if a family using the military fee assistance program receives funds from another federal
        child care subsidy program.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Additional Document</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please see a list of additional documents that may be
        required:</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;1. (Coordinators will fill
        out the list.)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Paper Application</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of the attached Military Fee Assistance Provider
        Application (please complete all required fields).</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><u><span
          style='font-family:"Arial",sans-serif;color:#1E35FF;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;Download Provider
          Application</span></u></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>Standard Agreement</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>A copy of the attached Provider Agreement Form (hand signed,
        dated, and all boxes checked)</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><strong><span
          style='font-family:"Arial",sans-serif;color:#212A3E;'>NAFMC Agreement</span></strong></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>Please complete the &nbsp;Navy, Air Force, and NGA Agreement
        section of the provider agreement form. It is required for eligibility with NAVY, AIRFORCE and NGA Families.</span>
    </p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>If you do not agree to the terms and policies please let us
        know.</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><span
        style='font-family:"Arial",sans-serif;color:#212A3E;'>&nbsp;</span></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><u><span
          style='font-family:"Arial",sans-serif;color:#1E35FF;'>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; Download Provider
          Agreement (ULA)</span></u></p>
    <p style="margin: 0cm 0cm 0cm 40px; font-size: 16px; font-family: Calibri, sans-serif;"><br></p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">These documents may be faxed to us at (703) 341-4146 or emailed to
        providerservices@usa.childcareaware.org. Please include your Provider ID number on the fax cover sheet or in the
        subject line of your email when sending in documents.&nbsp;</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">We look forward to resolving these issues. If you have questions, you
        may contact the Provider Services Department between 8:00 am and 7:00 pm eastern time by phone or email. If at any
        time you need to contact Child Care Aware<strong>&reg;</strong> of America about your application please include the
        following:</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Provider Number: ______</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Provider Name: ________</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Thank you for your service to our country!</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:8.0pt;margin-left:36.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <br>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Sincerely,</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <strong><span style="font-size:16px;line-height:107%;">Provider Services</span></strong>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Child Care Aware<strong>&reg;</strong> of America&nbsp;</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">1515 N. Courthouse Rd</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Arlington, VA 22201</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Phone: 1-800-424-2246, Option 4</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <span style="font-size:16px;line-height:107%;">Fax: 703-341-4146</span>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <a href="mailto:Provider.Services@usa.childcareaware.org"><span
          style="font-size:16px;line-height:107%;">ProviderServices@usa.childcareaware.org</span></a>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <br>
    </p>
    <p
      style='margin-top:0cm;margin-right:0cm;margin-bottom:0cm;margin-left:34.0pt;line-height:107%;font-size:15px;font-family:"Calibri",sans-serif;'>
      <br>
    </p>
    `,
  },
  {
    title: 'Staff Checklist',
    description: 'Staff Checklist Template',
    content: `
      <div style="font-size: 16px; font-family: Rubik, sans-serif;">
        <p style="margin: 0 0 5px;">
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">Dear <b>{$contactName}</b>,</span>
        </p>
        <p style="margin: 0 0 5px;">
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">[Military] Provider,</span>
        </p>
        <p style="margin: 0 0 5px;">
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">Provider ID#: [</span>
          <strong style="font-size: 1em; font-family: Rubik, sans-serif;">{$id}</strong>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">]</span>
        </p>
        <p>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">Thank you for your interest in Child Care Aware® of America. We require information for your Military Provider Child Care Fee Assistance application. In order to determine your eligibility, we need the information and documents. Fee assistance cannot begin until we receive the following:</span>
        </p>
      </div>
      <hr />
      <div style="font-size: 16px; font-family: Rubik, sans-serif;">
        <p>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Dear</span>
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>{$businessName}</strong>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>,</span>
        </p>
        <p>
        <strong style='font-size: 1em; font-weight: 800; font-family: "Rubik, sans-serif", sans-serif;'>Background Check Requirements</strong>
        <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>:</span>
        </p>
        <ol>
          <li>
            <span style='font-size: 1em; font-weight: 800; font-family: "Rubik, sans-serif", sans-serif;'>John Doe</span>
            <ol style="padding-left: 15px;">
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>FBI Fingerprint Background Check</span>
              </li>
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>State Criminal History Repository Check</span>
              </li>
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>State Child Abuse and Neglect Registry Check</span>
              </li>
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>Sex Offender Check (State/National)</span>
              </li>
            </ol>
          </li>
          <li>
            <span style='font-size: 1em; font-weight: 800; font-family: "Rubik, sans-serif", sans-serif;'>Kate Doe</span>
            <ol style="padding-left: 15px;">
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>FBI Fingerprint Background Check</span>
              </li>
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>State Criminal History Repository Check</span>
              </li>
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>State Child Abuse and Neglect Registry Check</span>
              </li>
              <li>
                <span style='font-family: "Rubik, sans-serif", sans-serif; font-size: 16px;'>Sex Offender Check (State/National)</span>
              </li>
            </ol>
          </li>
        </ol>
        <p>
          <br />
        </p>

        <p>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Your deadline for submitting all documents, unless otherwise noted, is:</span>
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>xx-xx-xxxx</strong>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>.</span>
        </p>
        <p>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>We thank you for your continued patience throughout this process. At any time if you have questions or concerns, please do not hesitate to contact us at the information below. We are available 8am to 7pm EST via phone or email. As a reference, your provider ID is</span>
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>[Provider ID#]</strong>
        </p>
        <p>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Sincerely,</span>
        </p>
        <p >
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Background Check Program</strong>
        </p>
        <p style="margin: 0;">
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Child Care Aware</span>
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>®</strong>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>of America</span>
        </p>
        <p style="margin: 0 0 5px;">
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>1515 N. Courthouse Road, 2</span>
          <sup style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>nd</sup>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Floor</span>
        </p>
        <p style="margin: 0 0 5px;">
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Arlington, VA 22201</span>
        </p>
        <p style="margin: 0 0 5px;">
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Email</strong>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>:</span>
          <a href="mailto:bgc@usa.childcareaware.org" target="_blank" style='font-size: 1em; color: rgb(5, 99, 193); font-family: "Rubik, sans-serif", sans-serif;'>bgc@usa.childcareaware.org</a>
        </p>
        <p style="margin: 0 0 5px;">
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Fax</strong>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>: 571-255-4911</span>
        </p>
        <p style="margin: 0 0 5px;">
          <strong style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>Phone</strong>
          <span style='font-size: 1em; font-family: "Rubik, sans-serif", sans-serif;'>: 1-800-424-2246, option 4</span>
        </p>
      </div>
      <hr />
      <div style="font-size: 16px; font-family: Rubik, sans-serif;">
        <p>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">These documents may be completed online by signing in to your account.</span>
        </p>
        <p>
          <a href="https://dev.greatchildcare.org/" target="_blank" style="color: rgb(5, 99, 193); font-size: large;">Link to Provider Portal</a>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">.</span>
        </p>
        <p>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">or faxed to us at</span>
          <strong style="font-size: 1em; font-family: Rubik, sans-serif;">(703) 341-4146)</strong>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">or emailed to providerservices@usa.childcareaware.org.</span>
        </p>
        <p>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">Please include your Provider ID number on the fax cover sheet or in the subject line of your email when sending in documents.</span>
        </p>
        <p>
          <span style="font-size: 1em; font-family: Rubik, sans-serif;">We look forward to resolving these issues. If you have questions, you may contact the Provider Services Department.</span>
        </p>
      </div>
    `,
  },
];

// These mappings are being used for dropdown options mock data for cypress
export const CYPRESS_MOCK_OPTION_PATHS = [
  '/common/programs?active=true',
  '/common/program-sponsors',
  '/common/program-types/family/components?branchId=2',
  '/common/program-types/family?programSponsorId=2&componentId=1&statusId=5',
  '/common/program-types/family/statuses/by-component-branch?programSponsorId=2&componentId=1',
  '/common/military-installations?programSponsorId=2',
  '/common/program-types/family/statuses',
  '/common/program-types',
  '/providers/countries/states/US',
  '/providers/training-types',
  '/families/eligibility-dates/types',
  '/families/certificates/types',
  '/families/application-status-types/internal',
  '/providers/application-status-types/internal',
  '/alerts/alerts/types',
  '/files/documents/types',
];
export const CYPRESS_FAMILY_MOCK_OPTIONS_COUNT = 30;
export const CYPRESS_PROVIDER_MOCK_OPTIONS_COUNT = 40;

export const PROGRAM_TYPES = {
  MCCYN: 1,
  ACCYN: 2,
  OMCC: 3,
  'Army Respite': 4,
  'EFM Respite': 5,
  CCYH: 6,
  TPU: 14,
  WDCC: 15,
  HCC: 16,
};

export const BRANCHES = {
  'Air Force': 1,
  Army: 2,
  Marines: 3,
  Navy: 4,
  NGA: 5,
};

export const EMPLOYMENT_STATUS = {
  EMPLOYED: 'Employed',
  UNEMPLOYED: 'Unemployed',
  NONEMPLOYED: 'Nonemployed',
};

export const employmentStatusTypes = [
  {
    id: EMPLOYMENT_STATUS.EMPLOYED,
    description: 'Currently Employed',
  },
  {
    id: EMPLOYMENT_STATUS.UNEMPLOYED,
    description: 'Not Employed - Looking for Work',
  },
  {
    id: EMPLOYMENT_STATUS.NONEMPLOYED,
    description: 'Not Employed',
  },
];

export const commonHead = `
<meta charset="UTF-8" />
    <meta http-equiv="X-UA-Compatible" content="IE=edge" />
    <meta name="viewport" content="width=device-width, initial-scale=1.0" />
    <link href="https://fonts.googleapis.com/css?family=Lato:400,400i,700,700i" rel="stylesheet" />
    <link href="https://fonts.googleapis.com/css?family=Lora:400,400i,700,700i" rel="stylesheet" />
    <style>
      .heading {
        margin:0;
        font-family:lora, georgia, serif;font-size:50px;
        font-style:normal;
        font-weight:normal;
        color:#333333;
      }
      .email-phone {
        margin:0;
        font-family:lato, 'helvetica neue', helvetica, arial, sans-serif;
        line-height:21px;
        color:#ffffff;
        font-size:14px;
      }
      .float-container {
        /* padding: 20px; */
        float: none;
      }
      .float-child {
        width: 50%;
        float: left;
        box-sizing: border-box;
        text-align: center;
      }
      .float-child img {
        margin-left: auto;
        margin-right: auto;
      }
      .left {
        border-right: solid 1px #ccc;
      }
      .float-child img.info-icon {
        margin: 0 auto 15px;
      }
      @media only screen and (max-width:600px) {
        .heading {
          font-size:36px;
        }
        .email-phone {
          line-height:18px;
          font-size:12px;
        }
        .float-child {
          float: none;
          width: 100%;
        }
        .float-child p {
          max-width: 300px;
          margin: 5px auto !important;
        }
        .left {
          border-right: none;
          margin-bottom: 20px;
        }
      }
    </style>
`;

export const EMAIL_TYPES = {
  SCHEDULED: 'scheduled',
  SENT: 'sent',
  INCOMING: 'incoming',
};

export const DASHBOARD_TYPES = {
  PROVIDERS: 'Providers',
  FAMILIES: 'Families',
};

export const FAMILY_DASHBOARD_COLUMNS = [
  'caseId',
  'familyId',
  'sponsor',
  'status',
  'daysInStatus',
  'startDate',
  'endDate',
  'branchProgram',
  'assignedTo',
  'reviewer',
  'actions',
];

export const PROVIDER_DASHBOARD_COLUMNS = [
  'providerId',
  'businessLegalName',
  'providerStreet',
  'providerCity',
  'providerState',
  'providerZip',
  'status',
  'daysInStatus',
  'assignedTo',
  'reviewer',
  'actions',
];
