import React, { useState } from 'react';
import { Switch, Redirect } from 'react-router-dom';

import Messages from './Messages';
import AddCenter from './AddCenter';
import UserProfile from './UserProfile';
import HelpTree from 'components/HelpTree';
import LayoutPage from 'components/Layout';
import CareFacilities from './CareFacilities';
import LoadingPage from 'components/LoadingPage';
import AttendanceSheets, { CareLocations } from './AttendanceSheets';
import ProtectedRoute from 'components/ProtectedRoute';
import { Building, Home, Result } from '@carbon/icons-react';
import BusinessProfile from './v2/BusinessProfile/BusinessProfile';
import { useDispatch } from 'react-redux';
import { providerService } from 'services';
import { useAsync } from 'react-async-hook';
import { actions } from 'features/auth';
import { Spin } from 'antd';
import SelectProgram from './SelectProgram';

let menuOptions = [
  {
    label: 'Applications',
    icon: Home,
    to: '/provider',
  },
  {
    label: 'Facilities',
    icon: Building,
    to: '/provider/facilities',
    match: [
      '/provider/facilities',
      '/provider/facility',
      /^\/provider\/facility\/\d+(.*)/,
      /^\/provider\/application\/\d+/,
    ],
  },
  {
    label: 'Business profile',
    icon: Result,
    to: '/provider/business-profile',
    match: ['/provider/business-profile'],
  },
  // {
  //   label: 'Messages',
  //   icon: BiEnvelope,
  //   to: '/provider/messages',
  //   match: ['/provider/messages'],
  // },
].filter(Boolean);

const rootBreadcrumb = { text: 'Provider', to: '/provider' };
const addFacilityRegex = /^\/provider\/facility(.*)$/;
const centerProfileRegex = /^\/provider\/profile\/(.*)$/;
const applicationDashboardRegex = /^\/provider\/application(.*)$/;
const breadcrumbs = [
  ['/provider', [rootBreadcrumb]],
  ['/provider/facilities', [rootBreadcrumb, { text: 'Facilities', to: '/provider/facilities' }]],
  ['/provider/user-profile', [rootBreadcrumb, { text: 'Profile', to: '/provider/user-profile' }]],
  [
    centerProfileRegex,
    [rootBreadcrumb, { text: 'Profile', to: '/provider/profile' }, { text: (params) => params.id, to: '' }],
  ],
  ['/provider/profile', [rootBreadcrumb, { text: 'Profile', to: '/provider/profile' }]],
  [
    applicationDashboardRegex,
    [rootBreadcrumb, { text: 'Application', to: '/provider/application' }, { text: (params) => params.id, to: '' }],
  ],
  ['/provider/messages', [rootBreadcrumb, { text: 'Messages', to: '/provider/messages' }]],
  [
    addFacilityRegex,
    [rootBreadcrumb, { text: 'Add Facility', to: '/provider/facility' }, { text: (params) => params.id, to: '' }],
  ],
];

export default function Provider(props) {
  // Organization data
  const dispatch = useDispatch();

  const [organization, setOrganization] = useState(null);
  // Fetch organization data
  useAsync(async () => {
    const _organization = await providerService.getOrganization();
    setOrganization(_organization);

    dispatch(actions.setOrganization(_organization));
  }, []);

  return (
    <LayoutPage {...props} menuOptions={menuOptions} breadcrumbs={breadcrumbs}>
      <React.Suspense fallback={<LoadingPage />}>
        <Switch>
          <ProtectedRoute exact path="/provider" roleRequired="Provider" message="Navigated to Provider Landing Page">
            <>
              {!organization && <Spin />}
              {organization && (
                <Redirect
                  to={!!organization.businessLegalName ? '/provider/facilities' : '/provider/business-profile'}
                />
              )}
            </>
          </ProtectedRoute>

          <ProtectedRoute exact path="/provider/help" roleRequired="Provider" message="Navigated to Help Page">
            <HelpTree />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/facilities"
            roleRequired="Provider"
            message="Navigated to Provider Landing Page"
          >
            <CareFacilities />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/business-profile"
            roleRequired="Provider"
            message="Navigated to Provider Landing Page"
          >
            <BusinessProfile />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/messages"
            roleRequired="Provider"
            message="Navigated to Provider Messages Page"
          >
            <Messages />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/attendance-sheets"
            roleRequired="Provider"
            message="Navigated to Provider Attendance Sheets Page"
          >
            <CareLocations />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/:id/attendance-sheets/:childId"
            roleRequired="Provider"
            message="Navigated to Provider Attendance Sheets Page"
          >
            <AttendanceSheets />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/facility/:id/select-program"
            roleRequired="Provider"
            message="Navigated to Select Program Page"
          >
            <SelectProgram />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/facility/:id/:step/:tab?"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/facility/:id"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>
          <ProtectedRoute
            exact
            path="/provider/facility"
            roleRequired="Provider"
            message="Navigated to Add Facility Page"
          >
            <AddCenter />
          </ProtectedRoute>

          <ProtectedRoute
            exact
            path="/provider/user-profile"
            roleRequired="Provider"
            message="Navigated to User Profile Page"
          >
            <UserProfile />
          </ProtectedRoute>

          <Redirect to="/provider" />
          {/* <ProtectedRoute message="Navigated to Parent Dashboard">
            <h3>No Content yet </h3>
          </ProtectedRoute> */}
        </Switch>
      </React.Suspense>
    </LayoutPage>
  );
}
