import PropTypes from 'prop-types';
import React, { useRef } from 'react';
import { Input, InputNumber } from 'antd';
import Form, { MAX_INPUT_PX_WIDTH } from 'components/Form';
import SearchAddress from 'components/SearchAddress';
import { AsyncSelect } from 'components/Select';
import FormItem from 'components/FormItem';
import { US_COUNTRY_CODES } from 'components/PlaceAutoComplete/PlaceAutoComplete';

export default function AddressFields({ searchRef, addressKey, required, disableFields, readOnly = false }) {
  const localRef = useRef(null);
  const inputRef = searchRef || localRef;
  const form = Form.useFormInstance();
  const isArray = Array.isArray(addressKey);

  const id = isArray ? [...addressKey, 'id'] : [addressKey, 'id'];
  const street = isArray ? [...addressKey, 'street'] : [addressKey, 'street'];
  const street2 = isArray ? [...addressKey, 'street2'] : [addressKey, 'street2'];
  const state = isArray ? [...addressKey, 'state'] : [addressKey, 'state'];
  const city = isArray ? [...addressKey, 'city'] : [addressKey, 'city'];
  const postalCode = isArray ? [...addressKey, 'postalCode'] : [addressKey, 'postalCode'];
  const county = isArray ? [...addressKey, 'county'] : [addressKey, 'county'];
  const lat = isArray ? [...addressKey, 'location', 'lat'] : [addressKey, 'location', 'lat'];
  const lon = isArray ? [...addressKey, 'location', 'lat'] : [addressKey, 'location', 'lon'];
  const description = isArray ? [...addressKey, 'description'] : [addressKey, 'description'];
  const placeId = isArray ? [...addressKey, 'placeId'] : [addressKey, 'placeId'];

  return (
    <div className={`max-w-[${MAX_INPUT_PX_WIDTH}px]`}>
      <FormItem name={id} hidden>
        <InputNumber />
      </FormItem>

      <FormItem
        name={street}
        label="ADDRESS LINE 1"
        rules={[{ required: required?.street, message: 'Street Address is required.' }]}
      >
        <SearchAddress
          ref={inputRef}
          country={US_COUNTRY_CODES}
          onSelect={(location) => {
            form.setFieldValue(addressKey, {
              ...location,
              postalCode: location?.zipCode,
              street2: '',
              location: {
                lat: location?.lat,
                lon: location?.lng,
              },
            });

            form.validateFields(
              ['country', 'city', 'state', 'postalCode'].map((name) => [
                isArray ? addressKey.join('_') : addressKey,
                name,
              ]),
            );
            inputRef.current.update(location?.street || '');
          }}
          disabled={disableFields || readOnly}
          readOnly={readOnly}
        />
      </FormItem>

      <FormItem name={street2} label="Address Line 2">
        <Input disabled={disableFields || readOnly} data-testid="AddressLine2" />
      </FormItem>

      <FormItem name={state} label="State" rules={[{ required: required?.state, message: 'State is required.' }]}>
        <AsyncSelect
          name="mailingAddress.state"
          placeholder="State"
          ariaLabel="State"
          disabled={disableFields || readOnly}
          optionsApiUrl="/countries/states/US"
          getOptionLabel={(opt) => opt.name}
          getOptionValue={(opt) => opt.code}
          isOptionSelected={(option, [value]) => option?.code === value}
          onChangeFormatter={(v) => v?.code}
          readOnly={readOnly}
        />
      </FormItem>

      <FormItem name={city} label="City" rules={[{ required: required?.city, message: 'City is required.' }]}>
        <Input disabled={disableFields || readOnly} readOnly={readOnly} data-testid="city" />
      </FormItem>

      <FormItem
        name={postalCode}
        label="Zip Code"
        rules={[
          { required: required?.postalCode, message: 'Zip Code is required.' },
          {
            pattern: /^\d{5}(-\d{4})?$/,
            message: 'Please enter a valid US zip code',
          },
        ]}
      >
        <Input disabled={disableFields || readOnly} readOnly={readOnly} data-testid="postalCode" />
      </FormItem>

      <div>
        <Form.Item name={county} hidden>
          <Input />
        </Form.Item>
        <Form.Item label="Latitude" name={lat} hidden>
          <InputNumber disabled={disableFields} />
        </Form.Item>
        <Form.Item label="Longitude" name={lon} hidden>
          <InputNumber disabled={disableFields} />
        </Form.Item>
        <Form.Item name={description} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={placeId} hidden>
          <Input />
        </Form.Item>
        <Form.Item name={id} hidden>
          <InputNumber />
        </Form.Item>
      </div>
    </div>
  );
}

AddressFields.propTypes = {
  addressKey: PropTypes.string,
  searchRef: PropTypes.any,
  showLatLng: PropTypes.bool,
  disableFields: PropTypes.bool,
  required: PropTypes.object,
};

AddressFields.defaultProps = {
  showSearch: true,
  showLatLng: false,
  disableFields: false,
  required: {
    country: true,
    city: true,
    state: true,
    postalCode: true,
    street: true,
  },
  searchCols: {
    xs: 24,
    sm: 12,
    md: 8,
  },
};
